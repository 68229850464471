.sideNavMain {
  width: 5rem;
  background-color: #323232;
  height: 100vh;
  position: fixed;
  text-align: center;
  top: 0;
  left: 0;
}

.sidNavLogo {
  width: 100%;
  padding: 0.6rem;
  padding-bottom: 0.2rem;
}

.subNavListItem {
  color: #fff;
  width: 4rem;
  height: 4rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  border-radius: 0.5rem;
  background-color: #3b3b3b;
  margin: 0.5rem;
  flex-direction: column;
  cursor: pointer;
}

.subNavList {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.subNavListItem:active {
  background-color: #848484 !important;
  font-size: 1.8rem !important;
}

.subNavListItem:hover {
  background-color: #4e4e4e;
  font-size: 2.2rem;
  transition: font-size 0.2s, background-color 0.2s;
}

.subNavListItem.active {
  background-color: #585858 !important;
  font-size: 2rem !important;
  cursor: inherit;
}

.subNavListItem.active::after {
  content: '•';
  font-size: 1rem;
  position: absolute;
  top: -0.2rem;
  right: 0.3rem;
}

.subNavListItem.disabled:hover,
.subNavListItem.disabled:active {
  color: #626262 !important;
  background-color: #3b3b3b !important;
  font-size: 2rem !important;
}

.subNavListItem.disabled {
  color: #626262;
  cursor: inherit;
}

.mainContent {
  margin-left: 5rem;
}

body {
  font-family: 'Roboto';
  color: #252525;
}

.tableContainer {
  background-color: #f2f2f2;
}

.tableControls {
  padding: 0.3rem;
  display: flex;
  align-items: center;
  height: 7vh;
  min-width: 43rem;
}

.columnsList {
  min-width: 25rem;
  padding: 1rem;
}

.selCollection {
  max-width: 7rem;
}

#selectEdit {
  max-width: none;
}

.mainTable {
  font-size: 0.8rem;
  background-color: #8c8c8c;
  overflow: auto;
  height: 90vh;
}

.sortSelector {
  position: absolute;
  right: 0.2rem;
  top: 50%;
  transform: translate(0rem, -0.3rem);
  cursor: pointer;
}

th {
  position: relative;
  vertical-align: middle;
  padding-right: 0.8rem !important;
}

.sortSelectorUp {
  position: absolute;
  right: 0;
}

.sortSelectorDn {
  position: absolute;
  right: 0;
}

.tableCell-Prod-ID {
}

.tableCell-ID {
  width: 7rem;
}

.tableCell-Name {
  width: 11rem;
}

.tableDropDown {
  font-size: 0.8rem;
}

td {
  vertical-align: middle;
  text-align: center;
}

.sortUp .sortSelectorDn {
  display: none;
}

.sortDn .sortSelectorUp {
  display: none;
}

.tableCell-Date {
  width: 6rem;
}

.columnFilter {
  width: 100%;
}

.columnFilter-Date {
  max-width: 7rem;
}

.columnFilter.columnFilter-num {
  width: 3rem;
}

.filterIcon.active {
  color: #0d6efd;
}

.filterIcon {
  cursor: pointer;
}

.filterCell {
  position: relative;
}

.filterBox {
  position: absolute;
  background-color: #b8b8b8;
  width: 9rem;
  height: 3rem;
  top: initial;
}

table {
  background-color: #fff;
}

.tableTitle {
  height: 5vh;
  border-bottom: solid 2px #858585;
}

.tableCell-ID {
  width: 9rem;
}

.tableCell-ID {
  width: 7rem;
}

.tableCell-Name {
  width: 7rem;
}

.tableCell-Med {
  width: 4rem;
}

.tableCell-Name-L {
  width: 11rem;
}

.tableCell-Num {
  width: 3rem;
}

.dashboardItems {
  display: flex;
  flex-wrap: wrap;
}

.dashboardItem:hover {
  background: #616161;
  padding-left: 2rem;
  transition: background 0.2s, padding-left 0.2s;
}

.dashboardItem:active {
  background: #1c1c1c;
  padding-left: 2.5rem;
}

.dashboardItem {
  background: #535353;
  width: 24rem;
  height: 15rem;
  border-radius: 0.5rem;
  margin: 1rem;
  padding: 0.5rem 1.5rem;
  color: #fff;
  font-size: 4rem;
  cursor: pointer;
}

.editIcon {
  cursor: pointer;
  font-size: 1.4rem;
  color: #4e4e4e;
  transition: color 0.2s, font-size 0.2s;
}

.editIcon:hover {
  color: #848484;
}

.editIcon:active {
  color: #000000;
  font-size: 1rem;
}

.LoginBG {
  background-color: #323232;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.LoginLogo {
  width: 8rem;
}

.LoginTitle {
  color: #fff;
  font-family: 'Roboto';
  font-weight: 300;
}

.LoginBox {
  width: 30rem;
  height: 17rem;
  background-color: #d7d7d7;
  margin: 2rem;
  border-radius: 0.5rem;
  padding: 2rem;
}

.mainEditArea {
  height: 95vh;
  background-color: #dcdcdc;
  padding: 2rem;
}

.editProdTextInput {
  width: 100%;
}

.contentFooter {
  height: 5vh;
  border-top: solid 2px #6f6f6f;
}

.collectionItem {
  width: 100%;
  background-color: #363636;
  color: #ffffff;
  border-radius: 0.3rem;
  padding: 0.5rem;
}

.collectionList {
  padding: 1rem;
}

.windowContainer {
  padding: 0.5rem;
}

.window {
  background-color: #dadada;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.collectionProductContainer {
  padding: 0.3rem;
  background-color: #f7f7f7;
  border-radius: 0.5rem;
  height: 34rem;
  width: 47rem;
  overflow-y: auto;
  border: solid 3px #a9a9a9;
}

.collectionProductImage {
  width: 7.6rem;
  height: 8rem;
  object-fit: cover;
  border: solid 2px #999999;
}

.collectionProduct {
  height: 15.7rem;
  width: 8.4rem;
  padding: 0.2rem;
  border-radius: 0.4rem;
  position: relative;
  margin: 0.4rem;
  background-color: #d0d0d0;
  border: solid 0.2rem #bcbcbc;
}

.searchProductResult {
  padding: 0.3rem;
  background-color: #f7f7f7;
  border-radius: 0.5rem;
  height: 26rem;
  overflow-y: auto;
  border: solid 3px #a9a9a9;
  display: flex;
}

.search-bar {
  height: 2rem;
  font-size: 1rem;
  padding: 0.25rem 0.6rem;
  border: solid;
  border-width: thin;
  border-color: #a7a7a7;
  border-radius: 0.25rem;
}

.suggest-list {
  background-color: rgb(112, 112, 112);
  width: 100%;
  position: absolute;
  top: 2rem;
}

.suggest-item:hover {
  color: #7f3f98;
  padding: 0.2rem 0rem 0.2rem 0.2rem;
}

.suggest-item:lang(ar-EG):hover {
  color: #7f3f98;
  padding: 0.2rem 0.2rem 0.2rem 0rem;
}

.suggest-item {
  height: 2.3rem;
  color: rgb(56, 56, 56);
  background-color: #ffffff;
  border: solid;
  border-width: 0px 1px 1px 1px;
  border-color: #cdcdcd;
  padding: 0.2rem;
  transition: padding 0.2s;
}

.search-icn {
  font-size: 1.2rem;
  position: absolute;
  right: 1rem;
  color: #919191;
}

.search-icn:lang(ar-EG) {
  left: 1rem;
  right: inherit;
}

.searchBarContainer {
  position: relative;
}

.suggestIcon {
  margin: 0.35rem;
  color: #5b5b5b;
}

.suggestIcon:lang(ar-EG) {
  margin: 0.35rem;
  color: #5b5b5b;
}

.suggestText {
  margin: 0.25rem;
  font-size: 1rem;
}

.removeProdBtn {
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  border-radius: 100%;
  color: white;
  position: absolute;
  top: -10px;
  right: -10px;
  transition: background-color 0.3s;
  background-color: #850000;
}

.addProdBtn {
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  border-radius: 100%;
  color: white;
  position: absolute;
  top: -10px;
  right: -10px;
  transition: background-color 0.3s;
  background-color: #438500;
}

.removeProdBtn:hover {
  background-color: #c10000;
}

.addProdBtn:hover {
  background-color: #097900;
}

.removeProdBtn:hover .fa,
.addProdBtn:hover .fa {
  font-size: 1.4rem !important;
  transition: font-size 0.3s;
}

.image-upload-box {
  border-color: #929292;
  background-color: #f5f5f5;
  position: relative;
}

.uploadImgParagraph {
  font-size: medium;
  position: absolute;
  top: 7px;
  margin-left: 10px;
  color: #7a7a7a;
  font-weight: bold;
}

.image-preview {
  margin-top: 2rem;
}

.imgSelContainer:before {
  content: ' ';
  position: absolute;
  top: 3px;
  left: 6px;
  font-size: 1rem;
  text-align: center;
  transition-duration: 0.2s;
  transform: scale(0);
}

.imgSelContainer {
  border: 0px solid #7b6a8e;
  padding: 0px;
  position: relative;
  margin: 0px;
  cursor: pointer;
  transition-duration: 0.2s;
  transform: scale(1);
}

input[type='radio'][id^='imgSel'] {
  display: none;
}

.image-preview-crop {
  width: 100px;
  height: 100px;
  overflow: hidden;
}

.image-preview-img {
  object-fit: cover;
  width: 100px;
  height: 100px;
}

.image-preview {
  margin-top: 2rem;
}

.selCoverImg {
  width: 100%;
}

.delLink:hover {
  color: #d30000;
}

.delLink {
  color: #6a0000;
  cursor: pointer;
}

.genLink {
  cursor: pointer;
}
.errorMessage {
  color: #db4437;
}
